<template>
    <div class="">
        <div class="py-24 flex items-center justify-center" v-if="isLoading">
            <Loader />
        </div>
        <div v-else class="infoComponent infoComponentClient">
            <div class="HeadingPart bg-primary-300">Tenant Information</div>
            <FormulateForm class="bg-white p-6">
            <div class="grid grid-cols-2 gap-x-6 infoHeadings">
                    <FormulateInput class="w-full" label="Tenant name" placeholder="Tenant name" type="text" :validation="checkTenantNameVal" :validation-messages="{ matches : 'Tenant name cannot contain number or any special characters'}" v-model="tenantInformation.title" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                        <div class="grid gap-2">
                            <label class="infoHeadings">
                                    Domain Name
                                    <span class="requiredFileds">*</span>
                                    <div class="customDomainInput flex mt-2 min-w-full">
                                        <input type="text" v-model="tenantInformation.domain_name"  placeholder="Domain Name" class="input input-bordered"
                                        :class="$route.params.tenant_id ? 'disabledInput' : ''"
                                        :disabled="$route.params.tenant_id" @input="handleDomainName"
                                        />
                                        <span class="flex justify-center items-center px-4">.delvium.xyz</span>
                                    </div>
                                    <span v-if="showErrors.error_filed_name == 'domain_name' && showErrors.has_error" class="text-red-400 text-xs">{{ showErrors.msg }}</span>      
                                </label>  
                        </div>
                        <!-- <label>
                            <FormulateInput  class="" label="Domain Name" placeholder="Domain Name" type="text" validation="required" :disabled="$route.params.tenant_id" v-model="tenantInformation.domain_name" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <span>.delvium.com</span>
                        </label> -->
                    <div>
                        <label>Onboarding date <span class="text-red-500 text-lg">*</span></label>
                        <input class="w-full input input-bordered onboardingDate" label="Onboarding date" validation="required" placeholder="Onboarding date" type="text" v-model="tenantInformation.created_at"
                        Disabled/>
                    </div>
                        <FormulateInput  class="w-full" label="Account Number" validation="required" placeholder="Account Number" type="text" v-model="tenantInformation.account_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            <FormulateInput class="w-full" label="Website" placeholder="Website" validation="required" type="url" v-model="tenantInformation.website" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Registration number" validation="required" placeholder="Company registration number" type="text" v-model="tenantInformation.registration_number" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                    <!--<FormulateInput class="w-full" label="Tenant client action alert emails" placeholder="Final report alert emails" type="pills" v-model="tenantInformation.final_report_alert_emails" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                        <FormulateInput class="w-full" label="Telephone" placeholder="Telephone" type="text" v-model="tenantInformation.telephone" :element-class=" (context, classes) => ['flex-1 min-w-full'].concat(classes)" @keypress="isIntegerNumber" />
                                        </div>
                <div class="grid grid-cols-2 gap-6 infoHeadings">
                    <FormulateInput type="image" name="Tenant logo" :uploader="uploadClientLogo" @file-removed="removeClientLogo()" :value="tenantInformation.logo_url" label="Tenant Logo" help="Select a png, jpg, jpeg or svg to upload." validation="mime:image/jpeg,image/png,image/svg+xml,image/jpeg" :maxSize="50" :validation-messages="{ mime: 'Client logo must be of type jpg/jpeg svg, png' }" />
                    <FormulateInput class="w-full" label="Tagline" placeholder="Tagline" type="text" v-model="tenantInformation.tagline" :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                </div>

                <label class="infoHeadings">Co-Branding</label><br>
                
                <div class="flex mt-1">
                    <div class="grid grid-cols-2 powered-by">
                        <label class="text-center toggle-label-btn">Powered By</label>
                        <div>
                            
                            <img :src="delviumLogo" class="flex-1 min-w-full" />
                        </div>
                    </div>
                    <div class="flex items-center justify-between ml-4">
                        <span class="toggle-label-btn pr-1">Disable</span>
                        <div class="toggle-btn-cnt">
                            <label class="switch">
                                <input type="checkbox" @click="handleToggle" :checked="tenantInformation.powered_by">
                                <span class="slider round"></span>
                            </label>
                        </div>
                        <span class="toggle-label-btn">Enable</span>
                    </div>-->
                </div>
                 <div class="grid grid-cols-2 gap-6 mb-2">
                                    <div>
                                    <h2 class="mb-1 mt-1">Dashboards *</h2>
    
                                    <Dropdown
                                        placeholder="Dashboards"
                                        v-model="configuration.dashboards"
                                        :options="dashboards"
                                        
                                        multiple
                                        :colors="{
                                            text: '#282828',
                                            bg: 'white',
                                            svg: '#282828',
                                            border: '#bcbcbc',
                                        }"
                                        @input="(value) => setTenantDashboards(value)"
                                        :config="{ label: 'name', trackBy: 'name' }"
                                        maxWidth="100%"
                                        minWidth="100%"
                                        width="102px"
                                        height="20px"
                                    />
                                </div>
                                </div>
                <div class="contactDetails">
                        <h1 class="text-gray-700 p-3 font-semibold contact bg-primary-300 text-lg ">Primary Contact</h1>
                        <div class="bg-white p-6 rounded-lg infoHeadings">
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Name" placeholder="Name" type="text"
                                    validation="required" v-model="contact.primary_contact.name"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                                <FormulateInput class="w-full" label="Position" placeholder="Position" type="text"
                                    v-model="contact.primary_contact.position"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :validation="checkPositionVal"
                                    :validation-messages="{ matches: 'Position cannot contain number or any special characters' }" />
                            </div>
                            <div class="grid grid-cols-2 gap-6">
                                <FormulateInput class="w-full" label="Phone" validation="required" placeholder="Phone "
                                    type="text" v-model="contact.primary_contact.phone"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    @keypress="isIntegerNumber" />
                                <!-- <FormulateInput class="w-full" label="Email" validation="required" placeholder="Email"
                                    type="email" v-model="contact.primary_contact.email"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :disabled="isEmailFilled" /> -->
                                    <div>
                                        <label>Email</label>
                                        <span class="text-red-500 ml-1 text-lg leading-3 mr-auto">*</span>
                                        <div>
                                    <input type="email" v-model="contact.primary_contact.email"  placeholder="Email" class="w-full input email-inp"
                                    :class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
                                    :disabled="isEmailFilled" />
                                </div>
                                </div>
                            </div>
                            <div class="flex 2xl:justify-end justify-between">
                                <div class=" w-full flex justify-between">
                                    <FormulateInput class="w-full" label="Received client action alert emails"
                                        placeholder="Received client action alert emails" type="checkbox"
                                        v-model="contact.primary_contact.receive_client_action_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                    <FormulateInput class="w-full" label="Received final report alert emails"
                                        placeholder="Received final report alert emails" type="checkbox"
                                        v-model="contact.primary_contact.receive_final_report_alert"
                                        :element-class="(context, classes) => ['mr-2'].concat(classes)" />
                                </div>
                            </div>
                            <div class="w-1/2">
                                <FormulateInput class="w-full" label="Note" placeholder="Note" type="textarea"
                                    v-model="contact.primary_contact.note"
                                    :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)" />
                            </div>
                        </div>
                       
                    </div>
            </FormulateForm>
        </div>
        <div class="flex mt-5 p-4 pr-8 justify-end w-full bg-white nextButton">
            <Button class="relative bottom-0" :text="$route.params.tenant_id ? 'Update & Next':'Save & Next'" :loader="isFormSubmit" type="primary" @click="handleSubmit" />
        </div>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import axios from "@/axios";
import Loader from "@shared/loader";
import {getTenantDetails, getAllDashboards, getTenantDashboards}  from "../../edit-tenant/services"
import { validDomainName, FormatDateNew } from "@/plugins/functions";
import { 
    // isNumber, 
    isIntegerNumber 
} from "@/plugins/functions";
// import { delviumLogo } from "@/plugins/common";
import Dropdown from "@shared/components/dropdown-base";
import { fetchClientContacts, getTenantContacts, validMail } from "../services";

export default {
    name: "tenantInformation",
    components: {
        Button,
        Loader,
        Dropdown
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        }
    },
    data() {
        return {
            tenantInformation: {
                title: null,
                created_at: FormatDateNew(new Date()),
                account_number: null ,
                website: null,
                registration_number: null,
                domain_name: null,
                email: null,
                key: "client_information",
                admin: {
                    email: "",
                    user_name: ""
                }
                // windowWidth: null,
            },
            clientId: null,
            isLoading: false,
            isShowError: false, 
            showErrors: {error_filed_name: '', has_error: false, msg: ''},
            contact: {
                primary_contact: {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                    key:"primary_contact"
                    
                },
                key: "contact"
            },
            loadingState: {
                submit: false,
                fetching: false,
            },
            configuration: {
                dashboards: []
            },
            isEmailFilled: false,
        };
    },
    // beforeUnmount() {
    //     window.removeEventListener('resize', this.handleWindowResize);
    // },
    computed: {

        isFormSubmit(){
            return this.loadingStates.formSubmit
        },
        permissionsList(){
            return this.tenantInformation.permissions
        },
        checkEmptyData() {
            return (
                this.tenantInformation?.title !== '' ||
                this.tenantInformation?.domain_name !== '' ||
                this.tenantInformation?.account_number !== '' ||
                this.tenantInformation?.website !== '' ||
                this.contact.primary_contact.name !== '' ||
                this.contact.primary_contact.position !== '' ||
                this.contact.primary_contact.phone !== '' ||
                this.contact.primary_contact.email !== ''
            );
        },
        checkTenantNameVal() {
            const matchesRegex = /^[a-zA-Z\s]+$/;
            if (
                this.checkEmptyData &&
                this.tenantInformation.title  !== '' &&
                !matchesRegex.test(this.tenantInformation.title )
            ) {
                return 'matches:/^[a-zA-Z\\s]+$/';
            }
            return '';
        },
        checkReqCondition() {
            if (this.checkEmptyData) {
                return 'required';
            } else {
                return '';
            }
        },
        // checkEmptyData() {
        //     return (
                
        //     );
        // },
        checkPositionVal() {
            const matchesRegex = /^[a-zA-Z\s]+$/;

            // if (
            //     this.checkEmptyData &&
            //     this.contact.accounts_contact.position !== '' &&
            //     !matchesRegex.test(this.contact.accounts_contact.position)
            // ) {
            //     return 'matches:/^[a-zA-Z\\s]+$/';
            // }

            if (
                this.checkEmptyData &&
                this.contact.primary_contact.position !== '' &&
                !matchesRegex.test(this.contact.primary_contact.position)
            ) {
                return 'matches:/^[a-zA-Z\\s]+$/';
            }
            return '';
        }
    },
    async mounted() { 
        if(this.$route.params?.tenant_id) {
            this.isLoading = true
            const {data} = await getTenantDetails(this.$route.params.tenant_id)
            console.log("getTenant", data)
            let {title, 
            created_at, 
            account_number, website,
            registration_number, 
            // final_report_alert_emails,
            // client_action_alert_emails,
            // telephone,
            // logo_url,
            domain_name,
            email,
            // powered_by,
            // tagline
            }= data
            this.tenantInformation = {
            title,
            created_at: created_at ? FormatDateNew(new Date(created_at)) : '-', 
            account_number, website, 
            registration_number, 
            // final_report_alert_emails,
            // client_action_alert_emails,
            // telephone,
            // logo_url : logo_url && [{url:logo_url}] || null,
            domain_name,
            email,
            // powered_by,
            // tagline
            }
            this.tenantInformation["key"] = "client_information";
            this.tenantInformation.admin = {
                "email":"",
                "user_name":""
            };
            this.$emit('enableAllSelection')
            this.isLoading = false
        }
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();

        if (this.$route.params?.tenant_id) {
            this.loadingState.fetching = true
            const { data } = await getTenantContacts(this.$route.params.tenant_id)
            const { contact } = data;
            this.contact = {
                primary_contact: contact?.primary_contact ? contact?.primary_contact : this.contact.primary_contact,
                // additional_contacts: contact?.additional_contacts ? contact?.additional_contacts : this.contact.additional_contacts
            }
            this.isEmailFilled = contact?.primary_contact?.email
            this.contact["key"] = "contact"
            this.tenantInformation.admin["email"] = contact?.primary_contact?.email;
            this.tenantInformation.admin["user_name"] = this.tenantInformation?.title
            this.$emit('enableAllSelection')
            this.loadingState.fetching = false
        }
        console.log("first");
        await this.getClientContacts();
        
        const allDashboards = await getAllDashboards()
            allDashboards?.data?.forEach((res)=>{
                res.dashboard_id = res.id
                res.id = res.quicksight_id 
            })
         this.dashboards = allDashboards?.data
         if(this.$route.params?.tenant_id) {
             this.loadingState.client = true
            // const {data} = await getTenantDetails(this.$route.params.tenant_id)
            // let {retention_period, max_candidates, max_users} = data
            // this.configuration = {
            //     retention_period,
            //     max_candidates,
            //     max_users
            // }
            // this.configuration["key"] = "configuration"
            await this.handleTenantDashboards()
            this.loadingState.client = false
    }
    },
    created()  {
        this.getWindowSize();
    window.addEventListener("resize", this.handleWindowResize);
    // console.log("update",this.windowWidth)
},

beforeMount()  {
    this.getWindowSize();
    window.removeEventListener("resize", this.handleWindowResize);
    // console.log("before",this.windowWidth)
},
    methods: {
        isIntegerNumber,
        // isNumber,
        validDomainName,
        FormatDateNew,
         setTenantDashboards(value) {
            console.log(value)
        },
        async getClientContacts() {
            console.log("second");
            this.loadingState.fetching = true;
            this.clientId = this.$route.params.clientId;
            if (this.clientId) {
                try {
                    let response = await fetchClientContacts(this.clientId);
                    this.contact = this.convertResponse(response);
                } catch (error) {
                    console.log(error);
                }
            }
            this.loadingState.fetching = false;
        },
         handleErros(key) {
            const { primary_contact} = this.contact;
            let contactError = false
            if (key == 'requiredValues') {
                    if (!primary_contact?.name ||
                        !primary_contact?.phone ||
                        !primary_contact?.email
                    ) {
                        contactError = true
                    }

                
                // if (accounts_contact?.name ||
                //     accounts_contact?.phone ||
                //     accounts_contact?.email) {
                //     {
                //         if (!accounts_contact?.name ||
                //             !accounts_contact?.phone ||
                //             !accounts_contact?.email) {
                //             contactError = true
                //         }
                //     }
                // }
            } else if (key == 'validMails') {
                if (primary_contact?.email || primary_contact?.name || primary_contact?.phone) {
                    if (!validMail(primary_contact?.email)) {
                        contactError = true;
                    }
                }
                // if (additional_contacts?.email || additional_contacts?.name || additional_contacts?.phone) {
                //     if (!validMail(additional_contacts?.email)) {
                //         contactError = true;
                //     }
                // }

            }
            // if (key == 'requiredValues') {
            //     let invalidAddress = additional_contacts?.find(contData => !contData?.name || !contData?.phone || !contData?.email)
            //     if (invalidAddress) {
            //         contactError = true
            //     }
            // } else if (key == 'validMails') {
            //     let invalidAddress = additional_contacts?.find(contData => !validMail(contData?.email))
            //     if (invalidAddress) {
            //         contactError = true
            //     }
            // }
            return contactError;
        },
        
        checkError() {
            const { title, domain_name, account_number, registration_number, created_at } = this.tenantInformation
            if (!title || 
                !domain_name || 
                !account_number || 
                !registration_number ||
                !created_at
            ) {
                return true;
            } else return false;
        },
        handleDomainName(e) {
            if (this.isShowError && e.target.value && !validDomainName(e.target.value)) {
                this.showErrors = {error_filed_name: 'domain_name', has_error: true, msg: 'Invalid domain name!'}
            } else {
                this.showErrors = {error_filed_name: 'domain_name', has_error: false, msg: ''}
            }
        },
        async handleTenantDashboards() {
            const dashboardData = await getTenantDashboards(this.$route.params.tenant_id)
            if (dashboardData?.data?.dashboards) {
            dashboardData.data.dashboards.forEach((res)=>{
                const allDashboardData = this.dashboards.find(r=>r.id == res.dashboard_id)
                if (allDashboardData) {
                    res.dashboard_id = allDashboardData.dashboard_id
                }
                res.id = res.dashboard_id
            })
            this.configuration["dashboards"] = dashboardData.data.dashboards
            }
            else {
                this.configuration["dashboards"] = []
            }
        },
        handleSubmit() {
            this.isShowError = true;
            const nameValMessages = this.checkTenantNameVal;
            this.loadingState.submit = true
            const positionValMessages = this.checkPositionVal;

            if (nameValMessages) {
                this.$toast.error('Please enter a valid Name');
                this.loadingState.submit = false;
                return;
            }
            let hasError = this.checkError();
            if (hasError) {
                this.$toast.error(`Please fill all the required fields`);
            } else if (!validDomainName(this.tenantInformation.domain_name)) {
                this.$toast.error('Special character/Capital letters are not allowed in domain name!');
                this.showErrors = {error_filed_name: 'domain_name', has_error: true, msg: 'Invalid domain name!'}
                return;
            } 
            

            else if (positionValMessages) {
                this.$toast.error('Please enter a valid Position');
                this.loadingState.submit = false;
                return;
            }
            else if (this.handleErros('requiredValues')) {
                this.$toast.error(`Please fill all the required fields`);
                this.loadingState.submit = false
            } else if (this.handleErros('validMails')) {
                this.$toast.error('Please enter a valid mail');
                this.loadingState.submit = false
            }
            else {
                // this.tenantInformation = {...this.tenantInformation,...this.contact}
                // if(this.additional_contacts?.length){
                //     this.contact = {...this.contact,...this.additional_contacts}
                // }

                // this.tenantInformation["key"] = "client_information"
                let newObj = {
                    "tenant_information": this.tenantInformation,
                    "contact": this.contact,
                }
                newObj.tenant_information["dashboards"] = this.configuration.dashboards;
                newObj.tenant_information["email"] = newObj?.contact?.primary_contact?.email;
                newObj.tenant_information.admin["email"] = newObj?.contact?.primary_contact?.email;
                newObj.tenant_information.admin["user_name"] = newObj?.tenant_information?.title;
                // newObj.tenant_information["logo_url"] = newObj?.tenant_information?.logo_url[0]?.url;
                this.$emit("submitForm", newObj);
                // this.$emit('handleNextSection', this.contact);
                // delete newObj.tenant_information["key"];
                this.loadingState.submit = false
            }
            
        },
        handleWindowResize() {
             this.getWindowSize();
        },
        getWindowSize() {
            this.windowWidth = window.innerHeight;
            console.log('windowWidth', this.windowWidth);
        },
        // async uploadClientLogo(file, progress) {
        //     // console.log(file, "file")
        //     if (file) {
        //         let allowedFiles = ["image/png", "image/svg+xml", "image/png", "image/jpeg"];
        //         if (!allowedFiles.includes(file.type)) return this.$toast.error("Only accept svg, png, jpg, jpeg");
        //     }
        //     if (file.size / 1e6 > 50) {
        //         this.tenantInformation.logo_url = null;
        //         return this.$toast.error("File size should be less than 50 MB");
        //     }
        //     progress(0);
        //     let url = `/client/logo`;
        //     let formData = new FormData();
        //     formData.append("file", file);
        //     try {
        //         let { data } = await axios.post(url, formData);
        //         this.tenantInformation = { ...this.tenantInformation, logo_url: [{ url: data?.data?.logo_url }] };
        //         progress(100);
        //     } catch (error) {
        //         this.$toast.error(error.response.data.detail || "Failed to upload logo");
        //         this.tenantInformation.logo_url = null;
        //         console.log(error, "error");
        //     }
        //     return Promise.resolve({});
        // },

        // async removeClientLogo() {
        //     if (!this.clientId) {
        //         let url = `/client/logo`;
        //         let payload = {
        //             logo_url: this.tenantInformation?.logo_url[0]?.url,
        //         };
        //         await axios.delete(url, { data: payload });
        //     }
        //     this.tenantInformation = { ...this.tenantInformation, logo_url: null };
        // },

        // handleToggle(e){
        //     console.log("e",e);
        //     this.tenantInformation.powered_by = e.target.checked
        //     return this.tenantInformation.powered_by
        // }
    },
        addNewContact() {
            this.contact?.additional_contacts?.push({
                name: "",
                position: "",
                phone: "",
                email: "",
                receive_client_action_alert: false,
                receive_final_report_alert: false,
                note: "",
            })
        },
        deleteCurrentContact(currentIndex) {
            this.contact?.additional_contacts?.splice(currentIndex, 1);
        },

        convertResponse(contacts) {
            if (contacts.length > 0) {
                let empty_contact = {
                    name: "",
                    position: "",
                    phone: "",
                    email: "",
                    receive_client_action_alert: false,
                    receive_final_report_alert: false,
                    note: "",
                }
                let primary_contact = contacts?.find((el) => el.contact_type === "Primary Contact") || empty_contact;
                let additional_contacts = contacts?.filter((el) => el.contact_type === "Additional Contact");
                let new_contacts = {
                    primary_contact,
                    // accounts_contact,
                    additional_contacts,
                };
                return new_contacts;
            } else {
                return this.contact;
            }
        },
        async removeOptionalContact(contacts) {
            let contact = contacts[0];
            if (contact && contact.id) {
                let url = `/client/${this.clientId}/contact/${contact.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message || "Removed!");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to remove!");
                }
            }
        },
};
</script>

<style lang="scss" scoped>
    .infoComponent {
        // height: 90vh;
        position: absolute;
        // z-index: 0;
    }
    .HeadingPart {
        // background-color: #99CBFF;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #282828;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .onboardingDate {
        height: 37px;
        border: 1px solid var(--theme-color-box-stroke);
        border-radius: .3rem;
    }
    .customDomainInput {
        max-width: 20em;
        input {
            width: 100%;
            height: 32px;
            border-right: none;
            border-radius: .3rem 0 0 .3rem;
        }

        span {
            border: 1px solid #cecece;
            border-left: none;
            border-radius: 0px .3rem .3rem 0px;
            opacity: 0.8;
            background: #F2F2F2;
            color: #0D69D5;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .disabledInput {
        border: 1px solid #cecece;
    }
    .infoHeadings {
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
    }

    .requiredFileds {
        color: #F00;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .nextButton {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 999;
    }
    .height{
        height: 53vh
    }
    // .powered-by{
    //     display: flex;
    //     flex-direction: column;
    //     width: 115px;
    //     padding: 6px;
    //     border: 1px solid #bab2b2c9;
    //     border-radius: 7px;
    // }
    // .powered-by>div{
    //     width: 100px;
    //     height: 20px;
    // }
    // .switch {
    //     position: relative;
    //     display: inline-block;
    //     // width: 60px;
    //     // height: 34px;
    // }

    // .switch input { 
    //     opacity: 0;
    //     width: 0;
    //     height: 0;
    // }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
        width: 44px;
        height: 21px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 17px;
        width: 17px;
        left: 5px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: #2196F3;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(18px);
        -ms-transform: translateX(18px);
        transform: translateX(18px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    
    .toggle-label-btn{
        font-size: 13px !important;
    }
    @media (max-height: 890px) {
        .infoComponentClient {
            height: 85vh;
        }
    }
    @media (max-height: 815px) {
        .infoComponentClient {
            height: 100vh;
        }
    }
    @media (max-height: 730px) {
        .infoComponentClient {
            height: 120vh;
        }
    }
</style>

<template>
  <div class="configComponent">
    <div
      class="bg-white p-6 rounded-lg py-24 flex items-center justify-center"
      v-if="loadingState.client"
    >
      <Loader />
    </div>
    <div v-else>
      <FormulateForm>
        <div class="flex flex-col gap-6">
          <!-- Configuration -->
          <div>
            <h1
              class="text-gray-700 font-semibold text-lg p-4 bg-primary-300 HeadingPart"
            >
              Configuration
            </h1>
            <div class="bg-white p-6 configDetails">
              <div class="grid grid-cols-2 gap-6 configData">
                <FormulateInput
                  class="w-full"
                  label="Retention Period (should be in Days)"
                  placeholder="Retention Period (should be in Days)"
                  type="number"
                  validation="required|number|min:1|max:30000|number|matches:/^[+]?\d+$/"
                  :validation-messages="{
                    matches: 'Retention period should be a whole number.',
                  }"
                  v-model="configuration.retention_period"
                  :element-class="
                    (context, classes) => ['flex-1 min-w-full'].concat(classes)
                  "
                  @keypress="isIntegerNumber"
                />
                <FormulateInput
                  class="w-full"
                  label="Max Users"
                  placeholder="Max Users"
                  validation="required|number|min:0,number"
                  type="number"
                  v-model="configuration.max_users"
                  :element-class="
                    (context, classes) => ['flex-1 min-w-full'].concat(classes)
                  "
                  @keypress="isIntegerNumber"
                />
              </div>
              <div class="grid grid-cols-2 gap-6">
                <FormulateInput
                  class="w-full"
                  label="Max Candidates"
                  placeholder="Max Candidates"
                  validation="required|number|min:0,number"
                  type="number"
                  v-model="configuration.max_candidates"
                  :element-class="
                    (context, classes) => ['flex-1 min-w-full'].concat(classes)
                  "
                  @keypress="isIntegerNumber"
                />
                <FormulateInput
                  class="w-full"
                  label="Tagline"
                  placeholder="Tagline"
                  type="text"
                  v-model="tenantInformation.tagline"
                  :element-class="
                    (context, classes) => ['flex-1 min-w-full'].concat(classes)
                  "
                />
              </div>
              <div class="grid grid-cols-2 gap-6">
                <label class="infoHeadings">Co-Branding</label><br />

                <div class="flex">
                  <div class="grid grid-cols-2 powered-by">
                    <label class="text-center toggle-label-btn"
                      >Powered By</label
                    >
                    <div>
                      <img :src="delviumLogo" class="flex-1 min-w-full" />
                    </div>
                  </div>
                  <div class="flex items-center justify-between ml-4">
                    <span class="toggle-label-btn pr-1">Disable</span>
                    <div class="toggle-btn-cnt">
                      <label class="switch">
                        <input
                          type="checkbox"
                          @click="handleToggle"
                          :checked="tenantInformation.powered_by"
                        />
                        <span class="slider round"></span>
                      </label>
                    </div>
                    <span class="toggle-label-btn">Enable</span>
                  </div>
                </div>
                <FormulateInput
                  type="image"
                  name="Tenant logo"
                  :uploader="uploadClientLogo"
                  @file-removed="removeClientLogo()"
                  :value="tenantInformation.logo_url"
                  label="Tenant Logo"
                  help="Select a png, jpg, jpeg or svg to upload."
                  validation="mime:image/jpeg,image/png,image/svg+xml,image/jpeg"
                  :maxSize="50"
                  :validation-messages="{
                    mime: 'Client logo must be of type jpg/jpeg svg, png',
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </FormulateForm>
    </div>
    <div class="flex mt-4 bg-white w-full justify-between p-4 buttonPanel">
      <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
      <Button
        :loader="isFormSubmit"
        :text="`${$route.params.tenant_id ? 'Update' : 'Save & Next'}`"
        type="primary"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script>
import Button from "@shared/components/button";
import { getTenantDetails } from "../../edit-tenant/services";
import Loader from "@shared/loader";
import { isIntegerNumber } from "@/plugins/functions";
// import Dropdown from "@shared/components/dropdown-base";
import { delviumLogo } from "@/plugins/common";
import axios from "@/axios";

export default {
  name: "client-configuration",
  components: {
    Button,
    Loader,
    // Dropdown
  },
  props: {
    clientDetails: {
      type: Object,
      default: () => {},
    },
    loadingStates: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      configuration: {
        retention_period: 0,
        max_users: 0,
        max_candidates: 1,
        key: "configuration",
        // dashboards: [],
      },
      loadingState: {
        client: false,
      },
      clientId: null,
      // dashboards: [],
      // selectedDashboards: [],
      tenantInformation: {
        logo_url: null,
        powered_by: null,
        tagline: null,
      },
      delviumLogo,
    };
  },
  async mounted() {
    //  const allDashboards = await getAllDashboards()
    //     allDashboards.data.forEach((res)=>{
    //         res.dashboard_id = res.id
    //         res.id = res.quicksight_id
    //     })
    //  this.dashboards = allDashboards.data
    if (this.$route.params?.tenant_id) {
      this.loadingState.client = true;
      const { data } = await getTenantDetails(this.$route.params.tenant_id);
      let {
        retention_period,
        max_candidates,
        max_users,
        logo_url,
        powered_by,
        tagline,
      } = data;
      this.configuration = {
        retention_period,
        max_candidates,
        max_users,
      };
      this.configuration["key"] = "configuration";
      this.tenantInformation = {
        logo_url: (logo_url && [{ url: logo_url }]) || null,
        powered_by,
        tagline,
      };
      //     await this.handleTenantDashboards()
      this.loadingState.client = false;
    }
  },
  computed: {
    isFormSubmit() {
      return this.loadingStates.formSubmit;
    },
  },
  methods: {
    isIntegerNumber,
    handleToggle(e) {
      console.log("e", e);
      this.tenantInformation.powered_by = e.target.checked;
      return this.tenantInformation.powered_by;
    },
    async removeClientLogo() {
      if (!this.clientId) {
        let url = `/client/logo`;
        let payload = {
          logo_url: this.tenantInformation?.logo_url[0]?.url,
        };
        await axios.delete(url, { data: payload });
      }
      this.tenantInformation = { ...this.tenantInformation, logo_url: null };
    },
    // setTenantDashboards(value) {
    //     console.log(value)
    // },
    // async handleTenantDashboards() {
    //     const dashboardData = await getTenantDashboards(this.$route.params.tenant_id)
    //     if (dashboardData?.data?.dashboards) {
    //     dashboardData.data.dashboards.forEach((res)=>{
    //         const allDashboardData = this.dashboards.find(r=>r.id == res.dashboard_id)
    //         if (allDashboardData) {
    //             res.dashboard_id = allDashboardData.dashboard_id
    //         }
    //         res.id = res.dashboard_id
    //     })
    //     this.configuration["dashboards"] = dashboardData.data.dashboards
    //     }
    //     else {
    //         this.configuration["dashboards"] = []
    //     }
    // },
    checkError() {
      const { retention_period, max_candidates, max_users } =
        this.configuration;
      if (retention_period <= 0 || max_candidates <= 0 || max_users <= 0) {
        return true;
      } else return false;
    },
    handleSubmit() {
      console.log(this.configuration);
      let hasErrors = this.checkError();
      if (hasErrors) {
        this.$toast.error(`Value should be greater than Zero`);
      }
      // else if (this.configuration.dashboards.length==0) {
      //     this.$toast.error("Please select a dashboard to continue")
      // }
      else {
        this.configuration = {
          ...this.configuration,
          ...this.tenantInformation,
        };
        this.configuration["max_users"] = parseInt(
          this?.configuration?.max_users
        );
        this.configuration["max_candidates"] = parseInt(
          this?.configuration?.max_candidates
        );
        this.configuration["retention_period"] = parseInt(
          this?.configuration?.retention_period
        );
        if (this?.configuration?.logo_url) {
          this.configuration["logo_url"] =
            this?.configuration?.logo_url[0]?.url;
        }
        let payload = { ...this.configuration };
        this.$emit("submitForm", payload);
      }
    },
    async uploadClientLogo(file, progress) {
      // console.log(file, "file")
      if (file) {
        let allowedFiles = [
          "image/png",
          "image/svg+xml",
          "image/png",
          "image/jpeg",
        ];
        if (!allowedFiles.includes(file.type))
          return this.$toast.error("Only accept svg, png, jpg, jpeg");
      }
      if (file.size / 1e6 > 50) {
        this.tenantInformation.logo_url = null;
        return this.$toast.error("File size should be less than 50 MB");
      }
      progress(0);
      let url = `/client/logo`;
      let formData = new FormData();
      formData.append("file", file);
      try {
        let { data } = await axios.post(url, formData);
        this.tenantInformation = {
          ...this.tenantInformation,
          logo_url: [{ url: data?.data?.logo_url }],
        };
        progress(100);
      } catch (error) {
        this.$toast.error(
          error.response.data.detail || "Failed to upload logo"
        );
        this.tenantInformation.logo_url = null;
        console.log(error, "error");
      }
      return Promise.resolve({});
    },
  },
};
</script>

<style lang="scss" scoped>
// .configComponent {
//     min-height: 86vh;
// }
.HeadingPart {
  // background-color: #99CBFF;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
  color: #000;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.configDetails {
  border-radius: 0px 0px 10px 10px;
  color: #282828;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.buttonPanel {
  position: absolute;
  left: 0;
  // bottom: 0;
}
.powered-by {
  display: flex;
  flex-direction: column;
  width: 115px;
  padding: 6px;
  border: 1px solid #bab2b2c9;
  border-radius: 7px;
}
.powered-by > div {
  width: 100px;
  height: 20px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  width: 44px;
  height: 21px;
}
.infoHeadings {
  color: #282828;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 5px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.switch {
  position: relative;
  display: inline-block;
  // width: 60px;
  // height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-btn-cnt {
  display: flex;
  align-items: center;
  width: 49px;
}
</style>

<template>
    <div>
        <div class="bg-white p-6 rounded-lg py-24 flex items-center justify-center" v-if="loadingState.address">
            <Loader />
        </div>
        <div v-else class="bg-white addressContainer">
            <div class="HeadingPart bg-primary-300">Allotted Features</div>
                <FormulateForm>
                <div class="flex flex-col gap-6 p-4">
                    <div class="bg-card-bg rounded-lg p-4 flex flex-col gap-y-3 xl:w-6/12 w-full h-full overflow-y-auto overflow-x-hidden"
                        style="min-height:300px">
                        <h1 class="text-gray-600 font-semibold flex">
                            <span>    
                                <div class="flex mr-3">
                                    <svg
                                        @click="handleSelectAll(selectedAll)"
                                        class="h-7 w-7 cursor-pointer"
                                        viewBox="0 0 16 16"
                                        :fill="selectedAll ? '#0D69D5' : 'grey'"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <g opacity="1">
                                        <g clip-path="url(#clip0_4665_6289)">
                                            <path
                                                d="M9.04766 12.0447H3.01553C1.35291 12.0447 0 10.6941 0 9.0338V3.01091C0 1.35084 1.35291 0 3.01553 0H9.04739C10.7103 0 12.0629 1.35084 12.0629 3.01091V9.03354C12.0629 10.6939 10.71 12.0444 9.04739 12.0444L9.04766 12.0447ZM3.01553 1.10734C1.96422 1.10734 1.10904 1.96121 1.10904 3.01091V9.03354C1.10904 10.0832 1.96422 10.9371 3.01553 10.9371H9.04739C10.0987 10.9371 10.9539 10.0832 10.9539 9.03354V3.01091C10.9539 1.96121 10.0987 1.10734 9.04739 1.10734H3.01553Z"
                                                fill="current-color"
                                            />
                                            <path
                                                d="M9.49067 13.9157H3.2214C2.91521 13.9157 2.66675 13.6679 2.66675 13.3619C2.66675 13.0559 2.91494 12.8081 3.2214 12.8081H9.4904C11.3284 12.8081 12.8235 11.3153 12.8235 9.48019V3.2208C12.8235 2.91507 13.0716 2.66699 13.3781 2.66699C13.6846 2.66699 13.9328 2.9148 13.9328 3.2208V9.48019C13.9328 11.926 11.9399 13.9155 9.49067 13.9155V13.9157Z"
                                                fill="current-color"
                                            />
                                            <path
                                                d="M10.129 16.028H5.88791C5.58171 16.028 5.33325 15.7801 5.33325 15.4742C5.33325 15.1682 5.58144 14.9203 5.88791 14.9203H10.1287C12.7793 14.9203 14.9356 12.7674 14.9356 10.1209V5.88681C14.9356 5.58109 15.1838 5.33301 15.4902 5.33301C15.7967 5.33301 16.0449 5.58082 16.0449 5.88681V10.1212C16.0449 13.3783 13.3911 16.028 10.129 16.028V16.028Z"
                                                fill="current-color"
                                            />
                                            <path
                                                d="M9.28787 3.71789C9.07124 3.50159 8.72026 3.50159 8.50364 3.71789L4.91673 7.2993L3.61345 5.99802C3.39682 5.78172 3.04585 5.78172 2.82922 5.99802C2.61259 6.21432 2.61259 6.56475 2.82922 6.78105L4.45568 8.40502C4.46728 8.41661 4.47969 8.42738 4.49183 8.43789C4.50235 8.45028 4.51288 8.4624 4.52475 8.47398C4.63292 8.58199 4.77483 8.63614 4.91673 8.63614C5.05863 8.63614 5.20053 8.58199 5.30871 8.47398L9.28787 4.50092C9.5045 4.28462 9.5045 3.93418 9.28787 3.71789Z"
                                                fill="current-color"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4665_6289">
                                            <rect width="16" height="16" fill="white" />
                                        </clipPath>
                                    </defs>
                                    </svg>
                                    <!-- <span v-if="selectAll" class="w-24 mt-1"> Select all</span> -->
                                </div>
                            </span>
                            Permissions 
                            <span class="text-red-400">*</span>
                        </h1>
                        <div class="permissionCard flex flex-col gap-y-3 overflow-y-auto justify-between flex-1">
                            <permissionsCard :Permissions="getPermissionsList"  @selectAllPer="selectAllPer($event)" @viewPermissionsInfo="viewPermissionsInfo($event)"
                                 />
                            <div v-if="permissions && permissions.length"  class="flex flex-1 justify-between items-center">
                               
                            </div>
                            <div v-else>
                                <Empty text="Result not found" />
                            </div>
                        </div>

                    </div>
                </div>
            </FormulateForm>
        </div>
        <div class="flex mt-4 bg-white w-full justify-between p-4 buttonPanel">
            <Button text="Back" type="outline" @click="$emit('handleBackSection')" />
            <Button :loader="loadingState.submit" :text="`${$route.params.tenant_id ? 'Update & Next' : 'Save and Tenant onboard'}`" type="primary" @click="handleSubmit" />
        </div>
        <Drawer ref="drawer">
        <div>
            <div class="bg-gray-100 text-base-content px-6 py-2 text-lg font-semibold flex items-center gap-3">
                <button @click="closeDrawer" class="h-6 w-6 flex justify-center cursor-pointer rounded-full items-center hover:bg-gray-300 text-gray-600 font-semibold">
                    <font-awesome-icon icon="times" />
                </button>
                Permission Information
                <span v-if="currentPermission && currentPermission.name" class="text-primary">
                    " {{currentPermission && currentPermission.name}} "
                </span> 
                
            </div>
            <div class="m-6">
                <h2 class="text-gray-600">
                    {{currentPermission && currentPermission.description}}
                </h2>

            </div>
        </div>
    </Drawer>
    </div>
</template>

<script>
import Button from "@shared/components/button";
import { getTenantPermissions, getTenantPermissionsList } from "../services";
import Loader from "@shared/loader";
import permissionsCard from "@shared/components/roles-and-permissions/permissionsCard";
const Drawer = () => import("@shared/components/drawer");


export default {
    name: "client-admin-address",
    components: {
        Button,
        Loader,
        permissionsCard,
        Drawer,
    },
    props: {
        loadingStates:{
            type:Object,
            default: () => {}
        },
        permissionsList:{
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            permissions: [
            {key: "configuration"}
            ],
            loadingState: {
                address: false,
                submit: false
            },
            selectedPermission: [{key: "allottedfeature"}],
            currentPermission: null,
            selectedAll: false,
        };
    },
    async mounted() {
        if(this.$route.params?.tenant_id){
            await this.setPermissionList()
        }
        else{
            await this.setPermission()
        }
           
    },
    methods: {
        async setPermissionList(){
            this.loadingState.address = true
            let data  = await getTenantPermissionsList(this.$route.params?.tenant_id);
            this.permissions = data.permissions;
            let allSeleted = this.permissions.find(el => !el.selected);
            if (allSeleted == '' || allSeleted == undefined) {
                this.selectedAll = true;
            }
            this.permissions["key"] = "allottedfeature"
            this.loadingState.address = false
        },
        async setPermission(){
            this.loadingState.address = true
            let data  = await getTenantPermissions();
            this.permissions = data;
            this.permissions["key"] = "allottedfeature"
            this.loadingState.address = false
        },
        handleSubmit() {
            this.loadingState.submit = true
            this.selectedPermission= []
            this.permissions.forEach((per)=>{
               this.selectedPermission =  [...this.selectedPermission, ...per.permissions]
            })
            this.selectedPermission = this.selectedPermission.filter((fil)=> {
                if(fil.selected) {
                    return fil.permission_id
                }
            })
            let payload = { "permission": this.selectedPermission, key: "allottedfeature"}
            // this.$emit("submitForm", {permission: this.selectedPermission, key: "allottedfeature"});
            this.$emit('handleNextSection',payload)
            this.loadingState.submit = false
        },
        selectAllPer({ input, index, all, per }) {
            input = input.target.checked;
            if (all) {
                this.permissions = this.permissions.map((el, id) => {
                    if (id === index) {
                        return { ...el, permissions: el.permissions.map((per) => ({ ...per, selected: input })) };
                    } else {
                        return el;
                    }
                });
            } else {
                this.permissions = this.permissions.map((el, elIndex) => {
                    if (per.group_name == 'Custom Domain' && per.name == 'Edit' && input && elIndex == index) {
                        el.permissions.forEach(chlel => chlel.selected = true)
                        el.selected = true
                        return el;
                    } else {
                        let permissions_checked_count = el.permissions.filter((el) => el.selected).length;
                        if (permissions_checked_count === el.permissions.length) {
                            return { ...el, selected: true };
                        } else {
                            return { ...el, selected: false };
                        }
                    }
                });
            }
            this.permissionsRowCLick(per);
        },
        handleSelectAll(isAllSeleted) {
            this.selectedAll = !isAllSeleted;
            this.permissions = this.permissions.map(el => {
                el.selected = this.selectedAll
                el.permissions = el.permissions.map(innerEl => {
                    innerEl.selected = this.selectedAll
                    return innerEl
                });
                return el
            });
        },
        openDrawer() {
            this.$refs.drawer.open();
        },
        closeDrawer() {
            this.$refs.drawer.close();
        },
        viewPermissionsInfo(event) {
            this.currentPermission = null;
            if (event && event.description) {
                this.currentPermission = event;
                this.openDrawer();
            }
        },
        permissionsRowCLick(event) {
            this.currentPermission = null;
            if (event && event.description && event.selected) {
                this.currentPermission = event;
                this.openDrawer();
            }
        },
    },
    computed: {
        getPermissionsList() {
            return this.permissions;
        },
    }
};
</script>

<style lang="scss" scoped>
    .dropdown{
        min-height: 40px !important;
    }

    .addressContainer {
        border-radius: 10px;
        min-height: 70vh;
    }

    .HeadingPart {
        // background-color: #99CBFF;
        padding: 10px;
        border-radius: 10px 10px 0px 0px;
        color: #000;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    
    .infoHeadings {
        color: #282828;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
    }

    .addressDetails {
        border-radius: 12px;
        border: 1px solid #DDD;
    }

    .address {
        border-radius: 12px 12px 0px 0px;
        background: #B4D6F9;
        color: #282828;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .buttonPanel {
        position: absolute;
        left: 0;
        // bottom: 0;
    }
    .permissionCard {
        max-height: 70vh;
    }
</style>
